"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMarketShareFromEquipmentManufacturer = exports.GetCarInformationFromEquipmentManufacturer = exports.GetCarInformationFromEVCCID = void 0;
const ee_nestjs_interfaces_1 = require("ee-nestjs-interfaces");
const knownMacAddressPrefixes = [
    {
        prefixes: ["001823", "00066E"],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.DELTA_ELECTRONICS,
            confirmedCarManufacturers: ["Ford", "Chevrolet"],
            confirmedCars: ["Mustang Mach E", "F150 Lightning", "Chevrolet Spark"],
        },
        marketShare: 0,
    },
    {
        prefixes: ["007DFA"],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.VOLKSWAGEN_GROUP_OF_AMERICA,
            confirmedCarManufacturers: ["Volkswagen"],
            confirmedCars: ["Volkswagen ID.4"],
        },
        marketShare: 0.0574,
    },
    {
        prefixes: [
            "0005C9",
            "001EB2",
            "0051ED",
            "0092A5",
            "044EAF",
            "147F67",
            "1C08C1",
            "203DBD",
            "24E853",
            "280FEB",
            "2C2BF9",
            "30A9DE",
            "402F86",
            "44CB8B",
            "4C9B63",
            "4CBAD7",
            "4CBCE9",
            "60AB14",
            "64CBE9",
            "7440BE",
            "7C1C4E",
            "805B65",
            "944444",
            "A06FAA",
            "A436C7",
            "ACF108",
            "B4E62A",
            "B8165F",
            "C4366C",
            "C80210",
            "CC8826",
            "D48D26",
            "D8E35E",
            "DC0398",
            "E8F2E2",
            "F896FE",
            "F8B95A",
        ],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.LG_INNOTEK,
            // TODO (unconfirmed): LG Innotek has been supplying electric vehicle parts such as a DC-DC converter and an Electric Vehicle Communication Controller (EVCC) to GM since 2010.
            suspectedCarManufacturers: ["GM"],
        },
        marketShare: 0.0586, //
    },
    {
        prefixes: ["44422F", "046565"],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.TESTOP,
            confirmedCarManufacturers: ["Kia (K-platform)"],
            confirmedCars: ["Kia Niro 2022"],
        },
        marketShare: 0.0,
    },
    {
        prefixes: ["00C059", "189578"],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.DENSO,
            // TODO (unconfirmed): Toyota owns a significant portion of DENSO.
            suspectedCarManufacturers: ["Toyota"],
            confirmedCarManufacturers: ["Subaru"],
            confirmedCars: ["Subaru Solterra"],
        },
        marketShare: 0.0049,
    },
    {
        prefixes: ["48C58D", "481693"],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.LEAR,
            confirmedCarManufacturers: ["Polestar"],
            confirmedCars: ["Polestar 2020"],
        },
        marketShare: 0.0,
    },
    {
        prefixes: ["0004EF"],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.POLESTAR,
        },
        marketShare: 0.0,
    },
    {
        prefixes: ["706979C"],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.RIVIAN,
            confirmedCarManufacturers: ["Rivian"],
        },
        marketShare: 0.0374,
    },
    {
        prefixes: ["7CBC844", "184CAE"],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.CONTINENTAL,
            // TODO (unconfirmed): Volkswagen is set to enter a new era in electric mobility with the launch of the ID.3, with which it has vital support from Continental. The technology company supplies key components that make the new compact electric model into a particularly safe, digital and long-range representative of its class.
            suspectedCarManufacturers: ["Volkswagen"],
        },
        marketShare: 0.0,
    },
    {
        prefixes: ["04E77E", "18B6CC", "9012A1", "E00EE1"],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.WE_CORPORATION,
            // TODO (unconfirmed): Is this just the Hyundai E-GMP platform?
            confirmedCarManufacturers: ["Hyundai", "Kia (e-GMP platform)"],
            confirmedCars: ["Ioniq 5", "EV6"],
        },
        marketShare: 0.0,
    },
    {
        prefixes: ["0C298F", "4CFCAA", "54F8F0", "98ED5C", "DC44271"],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.TESLA,
            confirmedCarManufacturers: ["Tesla"],
        },
        marketShare: 0.582,
    },
    {
        prefixes: [
            "000993",
            "000A30",
            "0CD9C1",
            "7CFC3C",
            "A8400B",
            "F855CD",
            "FC35E6",
        ],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.VISTEON,
        },
        marketShare: 0.0,
    },
    {
        prefixes: ["AC965B"],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.LUCID,
        },
        marketShare: 0.0052,
    },
    {
        prefixes: ["D00EA4"],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.PORSCHE,
        },
        marketShare: 0.0,
    },
    {
        prefixes: ["0001A9"],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.BMW,
        },
        marketShare: 0.0325,
    },
    {
        prefixes: ["0026B4"],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.FORD,
        },
        marketShare: 0.0687,
    },
    {
        prefixes: ["F07F0C"],
        information: {
            manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.KOSTAL,
        },
        marketShare: 0.0,
    },
];
const prefixToInformation = new Map();
knownMacAddressPrefixes.forEach((m) => m.prefixes.forEach((p) => prefixToInformation.set(p, m.information)));
const manufacturerToInformation = new Map();
knownMacAddressPrefixes.forEach((m) => manufacturerToInformation.set(m.information.manufacturer, m.information));
const manufacturerToMarketShare = new Map();
knownMacAddressPrefixes.forEach((m) => manufacturerToMarketShare.set(m.information.manufacturer, m.marketShare));
function GetCarInformationFromEVCCID(evccid) {
    const prefix6 = evccid.substring(0, 6);
    let information = prefixToInformation.get(prefix6);
    if (information) {
        return information;
    }
    const prefix7 = evccid.substring(0, 7);
    information = prefixToInformation.get(prefix7);
    if (information) {
        return information;
    }
    return {
        manufacturer: ee_nestjs_interfaces_1.EquipmentManufacturer.UNKNOWN,
    };
}
exports.GetCarInformationFromEVCCID = GetCarInformationFromEVCCID;
function GetCarInformationFromEquipmentManufacturer(manufacturer) {
    return manufacturerToInformation.get(manufacturer);
}
exports.GetCarInformationFromEquipmentManufacturer = GetCarInformationFromEquipmentManufacturer;
function GetMarketShareFromEquipmentManufacturer(manufacturer) {
    return manufacturerToMarketShare.get(manufacturer);
}
exports.GetMarketShareFromEquipmentManufacturer = GetMarketShareFromEquipmentManufacturer;
