import { useState } from "react";
// Persist some state across reloads/refreshes/back button hits
export function usePersistedState(key, initialValue) {
    var _a = useState(localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key))
        : initialValue), state = _a[0], setState = _a[1];
    var setPersistedState = function (value) {
        localStorage.setItem(key, JSON.stringify(value));
        setState(value);
    };
    return [state, setPersistedState];
}
