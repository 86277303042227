var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "../styles/globals.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, BrowserRouter, Routes, useLocation } from "react-router-dom";
import { SideNav } from "./Components/SideNav";
import { Dashboard } from "./Pages/Dashboard";
import { Stations } from "./Pages/Stations";
import { Station, StationRedirect } from "./Pages/Station";
import useSWR, { SWRConfig } from "swr";
import { process } from "./Process";
import { Auth0Fetcher } from "./Auth0Fetcher";
import { Transaction } from "./Pages/Transaction";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, ArcElement, Legend, Filler, BarElement, } from "chart.js";
import { OrganizationSelection } from "./Components/OrganizationSelection";
import { ErrorBoundary } from "react-error-boundary";
import { Customize } from "./Pages/Customize";
import { UserTags } from "ee-nestjs-interfaces";
import { ManageUsers } from "./Pages/ManageUsers";
import Footer from "./Components/Footer";
import { usePersistedState } from "./utils/localStorage";
/**
 * The refresh interval in ms to use as the SWR default.
 */
var SWR_REFRESH_INTERVAL_MS = 5000;
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, ArcElement, BarElement);
function hasMetadata(user) {
    return (Object.entries(user).find(function (entry) {
        return entry[0].endsWith("/metadata");
    })[1].resources != null);
}
function organizationIdsFromUser(user) {
    return Object.entries(user).find(function (entry) { return entry[0].endsWith("/metadata"); })[1].resources.organization;
}
function tagsFromUser(user) {
    return Object.entries(user).find(function (entry) { return entry[0].endsWith("/metadata"); })[1].resources.additional_tags;
}
function userHasChargePointVendor(user) {
    var tags = tagsFromUser(user);
    return (tags === null || tags === void 0 ? void 0 : tags.find(function (t) { return t == UserTags.TAG_CHARGE_POINT_VENDOR; })) != null;
}
function userHasWriteAccess(user) {
    var tags = tagsFromUser(user);
    return (tags === null || tags === void 0 ? void 0 : tags.find(function (t) { return t == UserTags.TAG_READ_ONLY; })) == null;
}
function userHasAdminAccess(user) {
    var tags = tagsFromUser(user);
    return (tags === null || tags === void 0 ? void 0 : tags.find(function (t) { return t == UserTags.TAG_ADMIN; })) != null;
}
var ErrorBoundaryWithReset = function (_a) {
    var children = _a.children;
    var fallback = function (props) {
        var location = useLocation();
        useEffect(function () {
            setTimeout(function () {
                props.resetErrorBoundary();
            }, 2000);
        }, [location.key]);
        return (React.createElement("div", { className: "w-full h-full min-h-[600px] flex flex-col items-center justify-center text-center" },
            React.createElement("label", { className: "font-N5 text-white w-[50%]" }, "Something went wrong. Please reach out to customer service.")));
    };
    return React.createElement(ErrorBoundary, { FallbackComponent: fallback }, children);
};
// Begin the application.
var App = function () {
    var _a;
    var _b = useAuth0(), user = _b.user, isAuthenticated = _b.isAuthenticated, isLoading = _b.isLoading, getAccessTokenSilently = _b.getAccessTokenSilently, loginWithRedirect = _b.loginWithRedirect;
    var data = useSWR(isLoading || !isAuthenticated
        ? null
        : ["".concat(process.env.NOC_SVC_URL, "/authorization"), getAccessTokenSilently], Auth0Fetcher).data;
    var isNoc = (data !== null && data !== void 0 ? data : false) && data.status === "Ok";
    var _c = useState(false), hideNocFeatures = _c[0], setHideNocFeatures = _c[1];
    var organizationSettings = useSWR(!user || isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/organization/features/").concat(organizationIdsFromUser(user)[0]),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var _d = usePersistedState("activeOrganization", undefined), activeOrganization = _d[0], setActiveOrganization = _d[1];
    var hasChargePointVendorAccess = user && hasMetadata(user) ? userHasChargePointVendor(user) : false;
    var hasWriteAccess = user && hasMetadata(user) ? userHasWriteAccess(user) : false;
    var hasAdminAccess = user && hasMetadata(user) ? userHasAdminAccess(user) : false;
    var displayNocFeatures = isNoc && !hideNocFeatures;
    // Automatically redirect to auth0 if we're not logged in.
    useEffect(function () {
        if (user && hasMetadata(user) && activeOrganization == undefined) {
            setActiveOrganization(organizationIdsFromUser(user)[0]);
        }
        void (function login() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(!isLoading && !user)) return [3 /*break*/, 2];
                            return [4 /*yield*/, loginWithRedirect()];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        })();
    }, [isLoading, user]);
    if (!user || !isAuthenticated) {
        return (React.createElement("div", { className: "w-full h-full min-h-[600px] flex flex-col items-center justify-center text-center" },
            React.createElement("label", { className: "font-N5 text-white w-[50%]" }, "Loading...")));
    }
    if (!hasMetadata(user)) {
        return (React.createElement("div", { className: "w-full h-full min-h-[600px] flex flex-col items-center justify-center text-center" },
            React.createElement("label", { className: "font-N5 text-white w-[50%]" }, "Your account has not yet been provisioned by an administrator. Please reach out to customer service.")));
    }
    var organizationIds = organizationIdsFromUser(user);
    return (React.createElement("div", { className: "flex flex-col min-h-screen" },
        (isNoc || organizationIds.length >= 1) && (React.createElement("div", { className: "absolute top-[8px] right-[8px] z-50" },
            React.createElement(ErrorBoundary, { fallback: React.createElement("div", null) },
                React.createElement(OrganizationSelection, { isNoc: isNoc, userOrganizationIds: organizationIds, selectedOrganizationId: activeOrganization, setOrganizationId: setActiveOrganization, hideNocFeatures: hideNocFeatures, setHideNocFeatures: setHideNocFeatures })))),
        React.createElement("div", { className: "grow flex flex-row" },
            React.createElement(SideNav, { customizationEnabled: (_a = organizationSettings === null || organizationSettings === void 0 ? void 0 : organizationSettings.themeCustomizationEnabled) !== null && _a !== void 0 ? _a : false, hasAdminAccess: hasAdminAccess, organizationId: activeOrganization }),
            React.createElement("div", { className: "grow flex flex-col pl-[32px]" },
                React.createElement("main", null,
                    React.createElement(ErrorBoundaryWithReset, null,
                        React.createElement(Routes, null,
                            React.createElement(Route, { path: "/:tab?", element: React.createElement(Dashboard, { isNoc: displayNocFeatures, organizationId: activeOrganization }) }),
                            React.createElement(Route, { path: "/stations", element: React.createElement(Stations, { isNoc: displayNocFeatures, organizationId: activeOrganization }) }),
                            React.createElement(Route, { path: "/customize", element: React.createElement(Customize, { isNoc: displayNocFeatures, organizationId: activeOrganization, readOnly: !hasWriteAccess }) }),
                            React.createElement(Route, { path: "/users", element: React.createElement(ManageUsers, { organizationId: activeOrganization, isNoc: isNoc }) }),
                            React.createElement(Route, { path: "/station", element: React.createElement(StationRedirect, { organizationId: activeOrganization }) }),
                            React.createElement(Route, { path: "/station/:stationId/:tab?", element: React.createElement(Station, { isNoc: displayNocFeatures, hasWriteAccess: hasWriteAccess, hasChargePointVendorAccess: hasChargePointVendorAccess }) }),
                            React.createElement(Route, { path: "/transaction/:transactionId", element: React.createElement(Transaction, { isNoc: displayNocFeatures }) })))))),
        React.createElement(Footer, null)));
};
var root = ReactDOM.createRoot(document.getElementById("root"));
root.render(React.createElement(Auth0Provider, { domain: process.env.AUTH0_DOMAIN, clientId: process.env.AUTH0_CLIENTID, authorizationParams: {
        redirect_uri: window.location.origin,
        audience: process.env.AUTH0_AUDIENCE,
    } },
    React.createElement(SWRConfig, { value: {
            refreshInterval: SWR_REFRESH_INTERVAL_MS,
            keepPreviousData: true,
        } },
        React.createElement(BrowserRouter, null,
            React.createElement(App, null)))));
