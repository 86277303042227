var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { StyledButton, StyledButtonStyle } from "../StyledButton";
import { SuccessIndicator } from "../SuccessIndicator";
import { HelpTooltip } from "../HelpTooltip";
import { useData } from "../../useData";
export var AddSingleScheduleModal = function (_a) {
    var pnid = _a.pnid;
    var _b = useAuth0(), isAuthenticated = _b.isAuthenticated, isLoading = _b.isLoading, getAccessTokenSilently = _b.getAccessTokenSilently;
    if (isLoading || !isAuthenticated) {
        return null;
    }
    var descriptionRef = useRef(null);
    var priorityRef = useRef(null);
    var gridLimitRef = useRef(null);
    var targetSOCRef = useRef(null);
    var expirationDateRef = useRef(null);
    var _c = useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState(), successMessage = _d[0], setSuccessMessage = _d[1];
    var displayErrorMessage = useCallback(function (message) {
        setErrorMessage(message);
        setSuccessMessage(undefined);
        setTimeout(function () {
            setErrorMessage(undefined);
        }, 10000);
    }, [setErrorMessage, setSuccessMessage]);
    var displaySuccessMessage = useCallback(function (message) {
        setSuccessMessage(message);
        setErrorMessage(undefined);
        setTimeout(function () {
            setSuccessMessage(undefined);
        }, 5000);
    }, [setSuccessMessage, setErrorMessage]);
    var handleSubmit = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var description, priority, schedule, response, _a, _b, _c, _d;
        var _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    if (isLoading ||
                        !isAuthenticated ||
                        !descriptionRef.current ||
                        !priorityRef.current ||
                        !gridLimitRef.current ||
                        !targetSOCRef.current ||
                        !expirationDateRef.current) {
                        return [2 /*return*/];
                    }
                    description = descriptionRef.current.value.trim();
                    priority = parseInt(priorityRef.current.value.trim());
                    if (description === "") {
                        setErrorMessage("Description is required.");
                        displayErrorMessage("Description is required.");
                        return [2 /*return*/];
                    }
                    if (isNaN(priority)) {
                        displayErrorMessage("Priority is required.");
                        return [2 /*return*/];
                    }
                    if (!expirationDateRef.current.value) {
                        displayErrorMessage("Expiration date is required.");
                        return [2 /*return*/];
                    }
                    schedule = {
                        description: description,
                        priority: priority,
                        expirationDate: new Date(expirationDateRef.current.value),
                        profiles: [
                            {
                                gridLimit: isNaN(parseInt(gridLimitRef.current.value))
                                    ? undefined
                                    : parseInt(gridLimitRef.current.value),
                                maxBatterySoc: isNaN(parseInt(targetSOCRef.current.value))
                                    ? undefined
                                    : parseInt(targetSOCRef.current.value),
                                cron: ["* * * * *"],
                            },
                        ],
                    };
                    _a = fetch;
                    _b = ["".concat(process.env.CORE_SVC_URL, "/powerprofiles/schedule/").concat(pnid)];
                    _e = {
                        method: "POST"
                    };
                    _f = {
                        "Content-Type": "application/json"
                    };
                    _c = "Bearer ".concat;
                    return [4 /*yield*/, getAccessTokenSilently()];
                case 1: return [4 /*yield*/, _a.apply(void 0, _b.concat([(_e.headers = (_f.Authorization = _c.apply("Bearer ", [_g.sent()]),
                            _f),
                            _e.body = JSON.stringify(schedule),
                            _e)]))];
                case 2:
                    response = _g.sent();
                    if (!response.ok) return [3 /*break*/, 3];
                    displaySuccessMessage("Schedule submitted successfully");
                    return [3 /*break*/, 5];
                case 3:
                    _d = displayErrorMessage;
                    return [4 /*yield*/, response.text()];
                case 4:
                    _d.apply(void 0, [_g.sent()]);
                    _g.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); }, [
        isLoading,
        isAuthenticated,
        descriptionRef,
        priorityRef,
        gridLimitRef,
        targetSOCRef,
        expirationDateRef,
        displaySuccessMessage,
        displayErrorMessage,
        getAccessTokenSilently,
        pnid,
    ]);
    return (React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px] flex flex-col gap-y-[32px] min-w-[650px]" },
        React.createElement("label", { className: "font-N7 text-white w-full text-left" }, "Add Single Schedule"),
        React.createElement("div", { className: "grid grid-cols-2 gap-x-4 gap-y-4 items-center" },
            React.createElement("label", { className: "font-N7 text-white" }, "Description:"),
            React.createElement("input", { type: "text", ref: descriptionRef, className: "border border-gray-300 rounded p-2", placeholder: "Enter description" }),
            React.createElement("label", { className: "font-N7 text-white" }, "Priority:"),
            React.createElement("input", { type: "number", ref: priorityRef, className: "border border-gray-300 rounded p-2", placeholder: "Enter priority" }),
            React.createElement("label", { className: "font-N7 text-white" }, "Grid Limit:"),
            React.createElement("input", { type: "number", ref: gridLimitRef, className: "border border-gray-300 rounded p-2", placeholder: "Enter grid limit" }),
            React.createElement("label", { className: "font-N7 text-white" }, "Target SOC:"),
            React.createElement("input", { type: "number", ref: targetSOCRef, className: "border border-gray-300 rounded p-2", placeholder: "Enter target SOC" })),
        React.createElement("div", { className: "grid grid-cols-2 gap-x-4 gap-y-4 items-center" },
            React.createElement("label", { className: "font-N7 text-white" }, "Expiration (Browser Local Time)"),
            React.createElement("input", { type: "datetime-local", ref: expirationDateRef, className: "border border-gray-300 rounded p-2", placeholder: "Select effective date" })),
        React.createElement("div", { className: "flex flex-row gap-x-4 mt-16" },
            React.createElement(StyledButton, { text: "Submit", style: StyledButtonStyle.Green, onClick: handleSubmit })),
        React.createElement(SuccessIndicator, { message: errorMessage, success: false }),
        React.createElement(SuccessIndicator, { message: successMessage, success: true })));
};
var PowerProfileLayeredScheduleConfigProps = /** @class */ (function () {
    function PowerProfileLayeredScheduleConfigProps() {
    }
    return PowerProfileLayeredScheduleConfigProps;
}());
export { PowerProfileLayeredScheduleConfigProps };
export var PowerProfileLayeredScheduleConfig = function (_a) {
    var pnid = _a.pnid;
    var _b = useAuth0(), isAuthenticated = _b.isAuthenticated, isLoading = _b.isLoading, getAccessTokenSilently = _b.getAccessTokenSilently;
    var editableRef = React.useRef(null);
    var _c = useState(), errorMessage = _c[0], setErrorMessage = _c[1];
    var _d = useState(), successMessage = _d[0], setSuccessMessage = _d[1];
    var displayErrorMessage = useCallback(function (message) {
        setErrorMessage(message);
        setSuccessMessage(undefined);
        setTimeout(function () {
            setErrorMessage(undefined);
        }, 10000);
    }, []);
    var displaySuccessMessage = useCallback(function (message) {
        setSuccessMessage(message);
        setErrorMessage(undefined);
        setTimeout(function () {
            setSuccessMessage(undefined);
        }, 5000);
    }, []);
    var currentSchedule = useData("".concat(process.env.CORE_SVC_URL, "/powerprofiles/layeredSchedule/").concat(pnid), !isLoading && isAuthenticated).data;
    useEffect(function () {
        if (editableRef.current) {
            // Strip ID out of all schedules and profiles so that when/if the schedule is submitted again, it creates a completely fresh layered schedule
            if (currentSchedule) {
                currentSchedule.schedules.forEach(function (schedule) {
                    schedule.profiles.forEach(function (profile) {
                        delete profile.id;
                    });
                    delete schedule.id;
                });
            }
            editableRef.current.innerHTML = JSON.stringify(currentSchedule, null, 2);
        }
    }, [currentSchedule]);
    var submitLayeredSchedule = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var schedule, response, _a, _b, _c, _d;
        var _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    if (!isAuthenticated || isLoading || !editableRef.current) {
                        return [2 /*return*/];
                    }
                    try {
                        schedule = JSON.parse(editableRef.current.innerText);
                    }
                    catch (e) {
                        displayErrorMessage(e.message);
                        return [2 /*return*/];
                    }
                    _a = fetch;
                    _b = ["".concat(process.env.CORE_SVC_URL, "/powerprofiles/layeredSchedule/").concat(pnid)];
                    _e = {
                        method: "POST"
                    };
                    _f = {
                        "Content-Type": "application/json"
                    };
                    _c = "Bearer ".concat;
                    return [4 /*yield*/, getAccessTokenSilently()];
                case 1: return [4 /*yield*/, _a.apply(void 0, _b.concat([(_e.headers = (_f.Authorization = _c.apply("Bearer ", [_g.sent()]),
                            _f),
                            _e.body = JSON.stringify(schedule),
                            _e)]))];
                case 2:
                    response = _g.sent();
                    if (!response.ok) return [3 /*break*/, 3];
                    displaySuccessMessage("Layered Schedule submitted successfully");
                    return [3 /*break*/, 5];
                case 3:
                    _d = displayErrorMessage;
                    return [4 /*yield*/, response.text()];
                case 4:
                    _d.apply(void 0, [_g.sent()]);
                    _g.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); }, [
        isLoading,
        isAuthenticated,
        editableRef,
        getAccessTokenSilently,
        pnid,
        displayErrorMessage,
        displaySuccessMessage,
    ]);
    return (React.createElement("div", null,
        React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px] flex flex-col gap-y-[32px] min-w-[650px]" },
            React.createElement("label", { className: "font-N7 text-white w-full text-left" }, "Layered Schedule Configuration"),
            React.createElement("div", { className: "grid grid-cols-1 gap-x-4 gap-y-4 items-center" },
                React.createElement("style", null, "\n              div[contenteditable=\"true\"] * {\n                font-family: monospace !important;\n              }\n            "),
                React.createElement("div", { ref: editableRef, contentEditable: true, className: "border border-gray-300 rounded p-2 font-mono", style: {
                        minHeight: "300px",
                        whiteSpace: "pre-wrap",
                        fontFamily: "monospace",
                        fontSize: "16px",
                    } })),
            React.createElement("div", { className: "flex items-center mt-8" },
                React.createElement(StyledButton, { text: "Submit Full Layered Schedule", style: StyledButtonStyle.Green, onClick: submitLayeredSchedule }),
                React.createElement(HelpTooltip, { text: "Submit the entire layered schedule. This will overwrite any existing schedule." }))),
        React.createElement(SuccessIndicator, { message: errorMessage, success: false }),
        React.createElement(SuccessIndicator, { message: successMessage, success: true })));
};
var PowerProfileConfigProps = /** @class */ (function () {
    function PowerProfileConfigProps() {
    }
    return PowerProfileConfigProps;
}());
export { PowerProfileConfigProps };
export var PowerProfileConfig = function (_a) {
    var pnid = _a.pnid;
    return (React.createElement("div", null,
        React.createElement(PowerProfileLayeredScheduleConfig, { pnid: pnid }),
        React.createElement(AddSingleScheduleModal, { pnid: pnid })));
};
