"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NormalizeStateToAbbreviation = void 0;
const stateToAbbreviation = new Map([
    ["alabama", "AL"],
    ["alaska", "AK"],
    ["arizona", "AZ"],
    ["arkansas", "AR"],
    ["california", "CA"],
    ["colorado", "CO"],
    ["connecticut", "CT"],
    ["delaware", "DE"],
    ["florida", "FL"],
    ["georgia", "GA"],
    ["hawaii", "HI"],
    ["idaho", "ID"],
    ["illinois", "IL"],
    ["indiana", "IN"],
    ["iowa", "IA"],
    ["kansas", "KS"],
    ["kentucky", "KY"],
    ["louisiana", "LA"],
    ["maine", "ME"],
    ["maryland", "MD"],
    ["massachusetts", "MA"],
    ["michigan", "MI"],
    ["minnesota", "MN"],
    ["mississippi", "MS"],
    ["missouri", "MO"],
    ["montana", "MT"],
    ["nebraska", "NE"],
    ["nevada", "NV"],
    ["new hampshire", "NH"],
    ["new jersey", "NJ"],
    ["new mexico", "NM"],
    ["new york", "NY"],
    ["north carolina", "NC"],
    ["north dakota", "ND"],
    ["ohio", "OH"],
    ["oklahoma", "OK"],
    ["oregon", "OR"],
    ["pennsylvania", "PA"],
    ["rhode island", "RI"],
    ["south carolina", "SC"],
    ["south dakota", "SD"],
    ["tennessee", "TN"],
    ["texas", "TX"],
    ["utah", "UT"],
    ["vermont", "VT"],
    ["virginia", "VA"],
    ["washington", "WA"],
    ["west virginia", "WV"],
    ["wisconsin", "WI"],
    ["wyoming", "WY"],
]);
/**
 * Normalizes input for state to an abbreviation if possible.
 *
 * @param state A field that may contain state information. If it does we'll normalize it to a state abbreviation.
 *
 * @returns The state abbreviation if the full name was matched or just the trimmed existing text.
 */
function NormalizeStateToAbbreviation(state) {
    return stateToAbbreviation.has(state?.trim().toLowerCase())
        ? stateToAbbreviation.get(state?.trim().toLowerCase())
        : state?.trim();
}
exports.NormalizeStateToAbbreviation = NormalizeStateToAbbreviation;
