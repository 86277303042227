import React from "react";
export var DynamicGridLimitInfoBadge = function (_a) {
    var dynamicGridLimit = _a.dynamicGridLimit, targetStateOfCharge = _a.targetStateOfCharge;
    return (React.createElement("div", { className: "absolute top-0 right-0 mt-8 mr-8 p-4 bg-black-800 text-white rounded-lg shadow-lg w-64" },
        React.createElement("div", { className: "flex flex-col gap-y-2" },
            React.createElement("div", null,
                React.createElement("label", { className: "font-N7" }, "Active Grid Limit:"),
                React.createElement("span", { className: "font-N7 ml-2" },
                    dynamicGridLimit,
                    " kW")),
            React.createElement("div", null,
                React.createElement("label", { className: "font-N7" }, "Active SOC Target:"),
                React.createElement("span", { className: "font-N7 ml-2" },
                    targetStateOfCharge,
                    "%")),
            React.createElement("div", null,
                React.createElement("label", { className: "font-N8 " }, "This Powernode is intelligently optimizing when and how it charges to reduce power tariff costs.")))));
};
