"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeFieldsByRegexes = exports.removeFieldsByRegex = exports.removeFields = exports.removeField = void 0;
/**
 * Removes a field from an object.
 *
 * This function traverses an object structure and removes a specified field.
 * It supports dot notation for nested fields, allowing for the removal of deeply
 * nested properties. When operating on arrays, it will remove the specified field
 * from all array elements.
 *
 * This implementation uses an iterative approach with a stack to avoid recursion.
 *
 * @example
 * // Remove simple field
 * removeField(user, 'password');
 *
 * @example
 * // Remove nested field
 * removeField(site, 'powernodes.chargers.serialNumber');
 *
 * @param obj - The object to modify. Can be a plain object or an array.
 * @param field - The field to remove. Can use dot notation for nested fields.
 * @returns void - The function modifies the object in place.
 */
function removeField(obj, field) {
    // Guard against null/undefined objects or non-objects.
    if (!obj || typeof obj !== "object")
        return;
    // Split the field path into individual parts.
    const parts = field.split(".");
    // Use a stack of tuples [object, path_parts].
    const stack = [[obj, parts]];
    while (stack.length > 0) {
        const [currentObj, remainingPath] = stack.pop();
        // Skip null/undefined objects.
        if (!currentObj || typeof currentObj !== "object")
            continue;
        const currentField = remainingPath[0];
        if (remainingPath.length === 1) {
            // Direct field to remove.
            if (Array.isArray(currentObj)) {
                for (const item of currentObj) {
                    delete item[currentField];
                }
            }
            else {
                delete currentObj[currentField];
            }
        }
        else {
            // Handle nested field.
            const nextPath = remainingPath.slice(1);
            if (Array.isArray(currentObj)) {
                for (const item of currentObj) {
                    if (item[currentField]) {
                        stack.push([item[currentField], nextPath]);
                    }
                }
            }
            else if (currentObj[currentField]) {
                stack.push([currentObj[currentField], nextPath]);
            }
        }
    }
}
exports.removeField = removeField;
/**
 * Removes multiple fields from an object.
 *
 * This is a convenience function that applies the removeField function to multiple
 * fields in a single call. It handles the same object structures and field notation
 * as removeField, but processes a batch of fields instead of just one.
 *
 * @example
 * // Remove multiple fields
 * removeFields(user, ['password', 'ssn', 'creditCardNumber']);
 *
 * @example
 * // Remove multiple nested fields
 * removeFields(site, [
 *   'powernodes.chargers.serialNumber',
 *   'address.base64EncodedAddressImage',
 *   'sensitiveData'
 * ]);
 *
 * @param obj - The object to modify. Can be a plain object or an array.
 * @param fields - Array of fields to remove. Each can use dot notation.
 * @returns void - The function modifies the object in place.
 */
function removeFields(obj, fields) {
    if (obj && typeof obj === "object") {
        fields?.forEach((field) => removeField(obj, field));
    }
}
exports.removeFields = removeFields;
/**
 * Removes fields from an object that match a regular expression.
 *
 * This function traverses an object structure and removes any fields whose
 * keys match the provided regex pattern. It processes all levels of the object,
 * including nested objects and arrays.
 *
 * This implementation uses an iterative approach with a stack to avoid recursion.
 *
 * @example
 * // Remove all fields containing "password"
 * removeFieldsByRegex(user, /password/i);
 *
 * @example
 * // Remove all fields that end with "Token"
 * removeFieldsByRegex(userData, /Token$/);
 *
 * @param obj - The object to modify. Can be a plain object or an array.
 * @param regex - Regular expression to match against field names.
 * @returns void - The function modifies the object in place.
 */
function removeFieldsByRegex(obj, regex) {
    // Guard against null/undefined objects or non-objects.
    if (!obj || typeof obj !== "object")
        return;
    // Use a stack to track objects to process.
    const stack = [obj];
    while (stack.length > 0) {
        const currentObj = stack.pop();
        // Skip null/undefined objects.
        if (!currentObj || typeof currentObj !== "object")
            continue;
        if (Array.isArray(currentObj)) {
            // For arrays, add each object element to the stack for processing.
            for (let i = 0; i < currentObj.length; i++) {
                if (typeof currentObj[i] === "object" && currentObj[i] !== null) {
                    stack.push(currentObj[i]);
                }
            }
        }
        else {
            // For objects, collect keys to remove to avoid modifying during iteration.
            const keysToRemove = [];
            const nestedObjects = [];
            // Iterate through all properties.
            for (const key in currentObj) {
                if (Object.prototype.hasOwnProperty.call(currentObj, key)) {
                    // Check if the key matches the regex.
                    if (regex.test(key)) {
                        keysToRemove.push(key);
                    }
                    else if (typeof currentObj[key] === "object" &&
                        currentObj[key] !== null) {
                        // Collect nested objects for later processing.
                        nestedObjects.push(currentObj[key]);
                    }
                }
            }
            // Remove all matched keys.
            for (const key of keysToRemove) {
                delete currentObj[key];
            }
            // Add nested objects to the stack for processing.
            stack.push(...nestedObjects);
        }
    }
}
exports.removeFieldsByRegex = removeFieldsByRegex;
/**
 * Removes fields matching any of the provided regular expressions from an object.
 *
 * This is a convenience function that applies the removeFieldsByRegex function
 * to multiple regex patterns in a single call.
 *
 * @example
 * // Remove fields matching multiple patterns
 * removeFieldsByRegexes(user, [/password/i, /token$/i, /^secret/]);
 *
 * @param obj - The object to modify. Can be a plain object or an array.
 * @param regexes - Array of regular expressions to match against field names.
 * @returns void - The function modifies the object in place.
 */
function removeFieldsByRegexes(obj, regexes) {
    if (obj && typeof obj === "object") {
        regexes?.forEach((regex) => removeFieldsByRegex(obj, regex));
    }
}
exports.removeFieldsByRegexes = removeFieldsByRegexes;
