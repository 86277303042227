import React, { useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { StyledButton, StyledButtonStyle } from "../StyledButton";
import { TextInput } from "../Input";
import { HelpTooltip } from "../HelpTooltip";
import { SuccessIndicator } from "../SuccessIndicator";
export var AlertSuppressionEditDialog = function (_a) {
    var suppression = _a.suppression, onClose = _a.onClose, onSubmit = _a.onSubmit, alertHistory = _a.alertHistory, onDelete = _a.onDelete;
    var user = useAuth0().user;
    var buttons = [
        {
            title: "30 minutes",
            value: 1000 * 60 * 30,
        },
        {
            title: "1 hour",
            value: 1000 * 60 * 60,
        },
        {
            title: "3 hours",
            value: 1000 * 60 * 60 * 3,
        },
        {
            title: "12 hours",
            value: 1000 * 60 * 60 * 12,
        },
        {
            title: "1 day",
            value: 1000 * 60 * 60 * 24,
        },
        {
            title: "7 days",
            value: 1000 * 60 * 60 * 24 * 7,
        },
    ];
    var expirationRef = useRef(null);
    var titlePatternRef = useRef(null);
    var tagPatternRef = useRef(null);
    var descriptionRef = useRef(null);
    var dateToInputString = function (date) {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toISOString().slice(0, 16);
    };
    var onButtonClick = function (value) {
        if (!expirationRef.current) {
            return;
        }
        var date = new Date();
        date.setMilliseconds(date.getMilliseconds() + value);
        expirationRef.current.value = dateToInputString(date);
    };
    var _b = useState(), errorMessage = _b[0], setErrorMessage = _b[1];
    var displayErrorMessage = function (message) {
        setErrorMessage(message);
        setTimeout(function () {
            setErrorMessage(undefined);
        }, 10000);
    };
    var filterAlertsByPattern = function (alerts, titlePattern, tagPattern) {
        var filteredAlerts = alerts;
        if (tagPattern) {
            // Filter out alerts that have details and do not have a tag value matching the pattern
            filteredAlerts = filteredAlerts.filter(function (a) {
                return a.details &&
                    Object.values(a.details).some(function (v) { return (v === null || v === void 0 ? void 0 : v.trim()) === tagPattern.trim(); });
            });
        }
        if (titlePattern) {
            filteredAlerts = filteredAlerts.filter(function (a) { var _a; return (_a = a.message) === null || _a === void 0 ? void 0 : _a.trim().includes(titlePattern); });
        }
        return filteredAlerts.map(function (a) {
            return a.message.replace("[Suppressed by Command Console] ", "");
        });
    };
    var _c = useState(suppression === null || suppression === void 0 ? void 0 : suppression.titlePattern), titlePattern = _c[0], setTitlePattern = _c[1];
    var _d = useState(suppression === null || suppression === void 0 ? void 0 : suppression.tagPattern), tagPattern = _d[0], setTagPattern = _d[1];
    var isFilterSet = titlePattern || tagPattern;
    var filteredAlerts = isFilterSet
        ? filterAlertsByPattern(alertHistory, titlePattern, tagPattern)
        : [];
    var onSave = function () {
        var _a;
        if (!expirationRef.current ||
            !titlePatternRef.current ||
            !tagPatternRef.current ||
            !descriptionRef.current) {
            return;
        }
        if (!expirationRef.current.value) {
            displayErrorMessage("Expiration must be set.");
            return;
        }
        if (!descriptionRef.current.value) {
            displayErrorMessage("Description must be set.");
            return;
        }
        // Get the suppression duration in milliseconds.
        var currentDate = new Date();
        var expirationDate = new Date(expirationRef.current.value);
        var durationMs = expirationDate.getTime() - currentDate.getTime();
        var twentyFourHoursMs = 1000 * 60 * 60 * 24; // 24 hours in milliseconds
        // Check if suppression duration is more than 24 hours.
        //
        // Require a title pattern for suppressions longer than 24 hours, to prevent rogue global
        // suppressions.
        if (durationMs > twentyFourHoursMs) {
            if (!titlePatternRef.current.value ||
                titlePatternRef.current.value.trim() === "") {
                displayErrorMessage("A title pattern is required for suppressions lasting more than 24 hours from now.");
                return;
            }
        }
        var alertToCreate = {
            id: suppression ? suppression.id : 0,
            description: descriptionRef.current.value,
            titlePattern: titlePatternRef.current.value
                ? titlePatternRef.current.value
                : undefined,
            tagPattern: tagPatternRef.current.value
                ? tagPatternRef.current.value
                : undefined,
            until: new Date(expirationRef.current.value),
            operator: (_a = user === null || user === void 0 ? void 0 : user.email) !== null && _a !== void 0 ? _a : "",
        };
        setErrorMessage(undefined);
        onSubmit(alertToCreate);
        onClose();
    };
    return (React.createElement("div", { className: "bg-black-1150 rounded-[20px] p-[32px] flex flex-col gap-y-[32px] min-w-[650px]" },
        React.createElement("label", { className: "font-N6 text-white w-full text-left" }, "Suppress alert if"),
        React.createElement("div", { className: "grid grid-cols-2 gap-x-4 gap-y-4 items-center" },
            React.createElement("label", { className: "font-N7 text-white" }, "Title contains:"),
            React.createElement("div", { className: "flex flex-row gap-x-2 items-center" },
                React.createElement(TextInput, { placeholder: "Title Pattern (optional)", ref: titlePatternRef, onChange: function () { var _a; return setTitlePattern((_a = titlePatternRef.current) === null || _a === void 0 ? void 0 : _a.value); }, defaultValue: suppression === null || suppression === void 0 ? void 0 : suppression.titlePattern }))),
        React.createElement("label", { className: "font-N6 text-white w-full text-left" }, "And (optionally)"),
        React.createElement("div", { className: "grid grid-cols-2 gap-x-4 gap-y-4 items-center" },
            React.createElement("label", { className: "font-N7 text-white" }, "Alert has a tag value which equals:"),
            React.createElement("div", { className: "flex flex-row gap-x-2 items-center" },
                React.createElement(TextInput, { placeholder: "Tag Pattern (optional)", ref: tagPatternRef, onChange: function () { var _a; return setTagPattern((_a = tagPatternRef.current) === null || _a === void 0 ? void 0 : _a.value); }, defaultValue: suppression === null || suppression === void 0 ? void 0 : suppression.tagPattern }),
                React.createElement(HelpTooltip, { text: "Each alert has additional key-value pairs that are added at creation time. In OpsGenie, these are called details. The OCCP ID of charger the fired the alert should be included as a tag (if applicable). Tag Pattern attempts to match against all values in the alert's KV pairs. Leave either box blank if you do not want to suppress alerts based on that attribute." })),
            isFilterSet && (React.createElement("div", { className: "flex flex-col gap-y-2 col-span-2" },
                filteredAlerts.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("label", { className: "font-N8 text-white" }, "Recent Matches"),
                    filteredAlerts.map(function (f, i) {
                        return (React.createElement("label", { className: "font-N8p5 text-white", key: i }, f));
                    }))),
                filteredAlerts.length == 0 && (React.createElement("label", { className: "font-N8 text-white" }, "No recent alert matches.")))),
            React.createElement("hr", { className: "border-black-800 max-w-[50%] col-span-2 mx-auto w-full" }),
            React.createElement("label", { className: "font-N7 text-white" }, "Description:"),
            React.createElement(TextInput, { placeholder: "Provide helpful description", ref: descriptionRef, defaultValue: suppression === null || suppression === void 0 ? void 0 : suppression.description }),
            React.createElement("hr", { className: "border-black-800 max-w-[50%] col-span-2 mx-auto w-full" }),
            React.createElement("label", { className: "font-N7 text-white" }, "Expiration:"),
            React.createElement(TextInput, { type: "datetime-local", ref: expirationRef, defaultValue: (suppression === null || suppression === void 0 ? void 0 : suppression.until)
                    ? dateToInputString(new Date(suppression.until))
                    : undefined }),
            React.createElement("div", { className: "flex flex-row flex-wrap col-span-2 gap-4 items-center" },
                React.createElement("label", { className: "font-N8 text-white" }, "Expiration Presets:"),
                buttons.map(function (b, i) { return (React.createElement(StyledButton, { key: i, text: b.title, onClick: function () { return onButtonClick(b.value); } })); })),
            React.createElement("div", { className: "flex flex-rowcol-span-2 gap-x-4 mt-16" },
                React.createElement(StyledButton, { text: "SAVE", style: StyledButtonStyle.Green, onClick: onSave }),
                onDelete != null && (React.createElement(StyledButton, { text: "DELETE", style: StyledButtonStyle.Green, onClick: function () {
                        onDelete();
                        onClose();
                    } })),
                React.createElement(StyledButton, { text: "CLOSE", style: StyledButtonStyle.Gray, onClick: onClose }))),
        React.createElement(SuccessIndicator, { message: errorMessage, success: false })));
};
