var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useRef, useMemo, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { process } from "../../Process";
import useSWR from "swr";
import { Auth0Fetcher } from "../../Auth0Fetcher";
import { GetPriceNumericValue, MovingAverage } from "ee-utils";
import { TextInput } from "../Input";
import { Radio } from "../Radio";
import { convertStringArrayToMultiSelectDropdownItems, MultiSelectDropdown, } from "../MultiSelectDropdown";
import { LineGraph } from "../Graph/LineGraph";
import { ComparisonStatCard } from "../Card";
import { NumberLocaleStringStyle } from "../../Util";
import { Dropdown } from "../Dropdown";
import { useSearchParams } from "react-router-dom";
var getPreviousQuarter = function (quarter, year) {
    var previousQuarter = quarter - 1;
    var previousYear = year;
    if (previousQuarter <= 0) {
        previousQuarter += 4;
        previousYear -= 1;
    }
    return { previousQuarter: previousQuarter, previousYear: previousYear };
};
var StatsBreakdown = function (_a) {
    var _b, _c;
    var regularTransactionStats = _a.stats, loyaltyTransactionStats = _a.loyaltyStats, movingAverageWindowSizeDays = _a.movingAverageWindowSizeDays, comparisonStats = _a.comparisonStats, comparisonLoyaltyStats = _a.comparisonLoyaltyStats, comparisonQuarter = _a.comparisonQuarter, comparisonYear = _a.comparisonYear, currentQuarter = _a.currentQuarter, currentYear = _a.currentYear;
    // Pull out the aggregate stats.
    var aggregate = regularTransactionStats.aggregateStats;
    var comparisonAggregate = comparisonStats === null || comparisonStats === void 0 ? void 0 : comparisonStats.aggregateStats;
    // Build out graph data - sessions and session reliability trend.
    var spdMovingAverage = new MovingAverage(movingAverageWindowSizeDays, regularTransactionStats === null || regularTransactionStats === void 0 ? void 0 : regularTransactionStats.dailySessions[0]);
    var spdSuccessfulMovingAverage = new MovingAverage(movingAverageWindowSizeDays, regularTransactionStats === null || regularTransactionStats === void 0 ? void 0 : regularTransactionStats.dailySuccessfulSessions[0]);
    // Build out graph data - sessions and session reliability trend.
    var comparisonSpdMovingAverage = new MovingAverage(movingAverageWindowSizeDays, comparisonStats === null || comparisonStats === void 0 ? void 0 : comparisonStats.dailySessions[0]);
    var comparisonSpdSuccessfulMovingAverage = new MovingAverage(movingAverageWindowSizeDays, comparisonStats === null || comparisonStats === void 0 ? void 0 : comparisonStats.dailySuccessfulSessions[0]);
    var graphSpdLabelSet = new Set();
    var graphSessionsData = regularTransactionStats
        ? regularTransactionStats.dailySessions.map(function (rawSpd, idx) {
            // Get data from the other arrays we need, and process through moving average filters.
            var rawSuccessfulSpd = regularTransactionStats.dailySuccessfulSessions[idx];
            spdMovingAverage.add(rawSpd);
            var spd = spdMovingAverage.get();
            spdSuccessfulMovingAverage.add(rawSuccessfulSpd);
            var spdSuccessful = spdSuccessfulMovingAverage.get();
            // Build timestamp.
            var d = new Date(aggregate.startTime);
            d.setDate(d.getDate() + idx);
            // Set up graph labels.
            var label = d.toLocaleDateString([], {
                month: "numeric",
                day: "numeric",
                year: "numeric",
            });
            graphSpdLabelSet.add(label);
            return {
                label: label,
                tooltip: d.toLocaleDateString([], {
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                }),
                spd: spd,
                spdFailed: spd - spdSuccessful,
                spdSuccessful: spdSuccessful,
                sessionReliability: (spdSuccessful / spd) * 100,
            };
        })
        : [];
    var graphCompareSessionsData = comparisonStats
        ? comparisonStats.dailySessions.map(function (rawSpd, idx) {
            // Get data from the other arrays we need, and process through moving average filters.
            var rawSuccessfulSpd = comparisonStats.dailySuccessfulSessions[idx];
            comparisonSpdMovingAverage.add(rawSpd);
            var spd = comparisonSpdMovingAverage.get();
            comparisonSpdSuccessfulMovingAverage.add(rawSuccessfulSpd);
            var spdSuccessful = comparisonSpdSuccessfulMovingAverage.get();
            // Build timestamp.
            var d = new Date(comparisonAggregate.startTime);
            d.setDate(d.getDate() + idx);
            // Set up graph labels.
            var label = d.toLocaleDateString([], {
                month: "numeric",
                day: "numeric",
                year: "numeric",
            });
            graphSpdLabelSet.add(label);
            return {
                label: label,
                tooltip: d.toLocaleDateString([], {
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                }),
                spd: spd,
                spdFailed: spd - spdSuccessful,
                spdSuccessful: spdSuccessful,
                sessionReliability: (spdSuccessful / spd) * 100,
            };
        })
        : [];
    var graphSessionsLabelAccessor = function (d) {
        return d.label;
    };
    var graphSpdValueAccessors = [
        {
            label: "Successful SPD",
            accessor: function (d) { return d.spdSuccessful; },
            colorOverride: 5,
        },
        {
            label: "Failed SPD",
            accessor: function (d) { return d.spdFailed; },
        },
        {
            label: "Successful SPD",
            accessor: function (d) { return d.spdSuccessful; },
        },
    ];
    var graphCompareSpdValueAccessors = [
        {
            label: "Successful SPD",
            accessor: function (d) { return d.spdSuccessful; },
        },
        {
            label: "Failed SPD",
            accessor: function (d) { return d.spdFailed; },
        },
        {
            label: "Successful SPD",
            accessor: function (d) { return d.spdSuccessful; },
        },
    ];
    var graphSessionsTooltipAccessor = function (d) {
        return "".concat(d.tooltip);
    };
    return (React.createElement("div", { className: "flex flex-col gap-y-[16px] mb-16" },
        React.createElement("label", { className: "font-N6 text-white mt-8" }, "Session Statistics"),
        React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
            React.createElement(ComparisonStatCard, { header: "Total Sessions", title: comparisonAggregate
                    ? "Q".concat(currentQuarter, " ").concat(currentYear, " | Q").concat(comparisonQuarter, " ").concat(comparisonYear, " | % change")
                    : "Total Sessions for the period", firstValue: aggregate.totalSessions, secondValue: comparisonAggregate === null || comparisonAggregate === void 0 ? void 0 : comparisonAggregate.totalSessions, numberFormatOptions: {
                    maximumFractionDigits: 0,
                    useLocaleString: true,
                } }),
            React.createElement(ComparisonStatCard, { header: "Total Successful Sessions", title: comparisonAggregate
                    ? "Q".concat(currentQuarter, " ").concat(currentYear, " | Q").concat(comparisonQuarter, " ").concat(comparisonYear, " | % change")
                    : "Total Successful Sessions for the period", firstValue: aggregate.totalSuccessfulSessions, secondValue: comparisonAggregate === null || comparisonAggregate === void 0 ? void 0 : comparisonAggregate.totalSuccessfulSessions, numberFormatOptions: {
                    maximumFractionDigits: 0,
                    useLocaleString: true,
                } }),
            React.createElement(ComparisonStatCard, { header: "Average Sessions Per Day", title: comparisonAggregate
                    ? "Q".concat(currentQuarter, " ").concat(currentYear, " | Q").concat(comparisonQuarter, " ").concat(comparisonYear, " | % change")
                    : "Average SPD for the period", firstValue: aggregate.successfulSessionsPerDay, secondValue: comparisonAggregate === null || comparisonAggregate === void 0 ? void 0 : comparisonAggregate.successfulSessionsPerDay }),
            React.createElement(ComparisonStatCard, { header: "Average Session Length", title: comparisonAggregate
                    ? "Q".concat(currentQuarter, " ").concat(currentYear, " | Q").concat(comparisonQuarter, " ").concat(comparisonYear, " | % change")
                    : "Average Session Length for the period", firstValue: aggregate.averageSessionLength, secondValue: comparisonAggregate === null || comparisonAggregate === void 0 ? void 0 : comparisonAggregate.averageSessionLength, isTimeInSeconds: true })),
        React.createElement("div", null,
            comparisonStats ? (React.createElement("label", { className: "font-N7 text-white mt-8" },
                "Q",
                currentQuarter,
                " ",
                currentYear)) : (React.createElement("label", { className: "font-N7 text-white mt-8" }, "Current Period")),
            React.createElement(LineGraph, { labelAccessor: graphSessionsLabelAccessor, valueAccessors: graphSpdValueAccessors, data: graphSessionsData, yAxisValueToLabel: function (v) { return "".concat(v.toFixed(2), " SPD"); }, manualOrder: true, tooltipLabelAccessor: graphSessionsTooltipAccessor })),
        comparisonStats && (React.createElement("div", null,
            React.createElement("label", { className: "font-N7 text-white mt-8" },
                "Q",
                comparisonQuarter,
                " ",
                comparisonYear),
            React.createElement(LineGraph, { labelAccessor: graphSessionsLabelAccessor, valueAccessors: graphCompareSpdValueAccessors, data: graphCompareSessionsData, yAxisValueToLabel: function (v) { return "".concat(v.toFixed(2), " SPD"); }, manualOrder: true, tooltipLabelAccessor: graphSessionsTooltipAccessor }))),
        React.createElement("hr", { className: "border-black-800 max-w-[50%]" }),
        React.createElement("label", { className: "font-N6 text-white mt-8" }, "Power, Energy, and Revenue"),
        React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
            React.createElement(ComparisonStatCard, { header: "Total Revenue", title: comparisonAggregate
                    ? "Q".concat(currentQuarter, " ").concat(currentYear, " | Q").concat(comparisonQuarter, " ").concat(comparisonYear, " | % change")
                    : "Total Revenue for the period", firstValue: GetPriceNumericValue(aggregate.totalRevenue, 2), secondValue: comparisonAggregate
                    ? GetPriceNumericValue(comparisonAggregate.totalRevenue, 2)
                    : undefined, numberFormatOptions: {
                    maximumFractionDigits: 0,
                    useLocaleString: true,
                    localeStringStyle: NumberLocaleStringStyle.Currency,
                } }),
            React.createElement(ComparisonStatCard, { header: "Total Energy Delivered", title: comparisonAggregate
                    ? "Q".concat(currentQuarter, " ").concat(currentYear, " | Q").concat(comparisonQuarter, " ").concat(comparisonYear, " | % change")
                    : "Total Energy Delivered for the period", firstValue: aggregate.totalEnergy, secondValue: comparisonAggregate === null || comparisonAggregate === void 0 ? void 0 : comparisonAggregate.totalEnergy, suffix: " kWh", numberFormatOptions: {
                    maximumFractionDigits: 0,
                    useLocaleString: true,
                } }),
            React.createElement(ComparisonStatCard, { header: "Avg. Energy Delivered Per Session", title: comparisonAggregate
                    ? "Q".concat(currentQuarter, " ").concat(currentYear, " | Q").concat(comparisonQuarter, " ").concat(comparisonYear, " | % change")
                    : "Avg. Energy Delivered Per Session for the period", firstValue: aggregate.averageEnergyKwh, secondValue: comparisonAggregate === null || comparisonAggregate === void 0 ? void 0 : comparisonAggregate.averageEnergyKwh, suffix: " kWh", numberFormatOptions: {
                    maximumFractionDigits: 0,
                    useLocaleString: true,
                } }),
            React.createElement(ComparisonStatCard, { header: "Avg. Peak Power Per Session", title: comparisonAggregate
                    ? "Q".concat(currentQuarter, " ").concat(currentYear, " | Q").concat(comparisonQuarter, " ").concat(comparisonYear, " | % change")
                    : "Avg. Peak Power Per Session for the period", firstValue: aggregate.averagePeakPowerKw, secondValue: comparisonAggregate === null || comparisonAggregate === void 0 ? void 0 : comparisonAggregate.averagePeakPowerKw, suffix: " kW", numberFormatOptions: {
                    maximumFractionDigits: 1,
                    useLocaleString: true,
                } })),
        React.createElement("hr", { className: "border-black-800 max-w-[50%]" }),
        React.createElement("label", { className: "font-N6 text-white mt-8" }, "Customer Loyalty Data"),
        React.createElement("div", { className: "flex flex-row flex-wrap gap-x-[16px]" },
            React.createElement(ComparisonStatCard, { header: "Total Sessions By Repeat Customers", title: comparisonAggregate
                    ? "Q".concat(currentQuarter, " ").concat(currentYear, " | Q").concat(comparisonQuarter, " ").concat(comparisonYear, " | % change")
                    : "Total Sessions By Repeat Customers", firstValue: loyaltyTransactionStats.totalSessionsByRepeatCustomers, secondValue: comparisonLoyaltyStats === null || comparisonLoyaltyStats === void 0 ? void 0 : comparisonLoyaltyStats.totalSessionsByRepeatCustomers, numberFormatOptions: {
                    maximumFractionDigits: 0,
                    useLocaleString: true,
                } }),
            React.createElement(ComparisonStatCard, { header: "Unique Customers", title: comparisonAggregate
                    ? "Q".concat(currentQuarter, " ").concat(currentYear, " | Q").concat(comparisonQuarter, " ").concat(comparisonYear, " | % change")
                    : "Total Unique Customers", firstValue: loyaltyTransactionStats.numberOfuniqueCustomers, secondValue: comparisonLoyaltyStats === null || comparisonLoyaltyStats === void 0 ? void 0 : comparisonLoyaltyStats.numberOfuniqueCustomers, numberFormatOptions: {
                    maximumFractionDigits: 0,
                    useLocaleString: true,
                } }),
            React.createElement(ComparisonStatCard, { header: "Repeat Customers", title: comparisonAggregate
                    ? "Q".concat(currentQuarter, " ").concat(currentYear, " | Q").concat(comparisonQuarter, " ").concat(comparisonYear, " | % change")
                    : "Total Repeat Customers", firstValue: loyaltyTransactionStats.numberOfRepeatCustomers, secondValue: comparisonLoyaltyStats === null || comparisonLoyaltyStats === void 0 ? void 0 : comparisonLoyaltyStats.numberOfRepeatCustomers, numberFormatOptions: {
                    maximumFractionDigits: 0,
                    useLocaleString: true,
                } }),
            React.createElement(ComparisonStatCard, { header: "Percentage Repeat Customers", title: comparisonAggregate
                    ? "Q".concat(currentQuarter, " ").concat(currentYear, " | Q").concat(comparisonQuarter, " ").concat(comparisonYear, " | % change")
                    : "Total Percentage of Repeat Customers", firstValue: Math.round((loyaltyTransactionStats.numberOfRepeatCustomers /
                    ((_b = loyaltyTransactionStats.numberOfuniqueCustomers) !== null && _b !== void 0 ? _b : 1)) *
                    100) / 100, secondValue: comparisonLoyaltyStats
                    ? (_c = (comparisonLoyaltyStats === null || comparisonLoyaltyStats === void 0 ? void 0 : comparisonLoyaltyStats.numberOfRepeatCustomers) /
                        (comparisonLoyaltyStats === null || comparisonLoyaltyStats === void 0 ? void 0 : comparisonLoyaltyStats.numberOfuniqueCustomers)) !== null && _c !== void 0 ? _c : 1
                    : comparisonLoyaltyStats, numberFormatOptions: {
                    maximumFractionDigits: 1,
                    useLocaleString: true,
                    localeStringStyle: NumberLocaleStringStyle.Percent,
                } })),
        React.createElement("hr", { className: "border-black-800 max-w-[50%]" })));
};
export var CustomerDashboardStats = function (_a) {
    var _b, _c, _d;
    var organizationId = _a.organizationId, isNoc = _a.isNoc;
    var _e = useAuth0(), getAccessTokenSilently = _e.getAccessTokenSilently, isLoading = _e.isLoading, isAuthenticated = _e.isAuthenticated;
    var _f = useSearchParams(), searchParams = _f[0], setSearchParams = _f[1];
    var isValidDate = function (date) {
        return !isNaN(date.getTime());
    };
    var formatDateString = function (dateString) {
        if (dateString) {
            var date = new Date(dateString);
            if (isValidDate(date)) {
                return date.toISOString();
            }
        }
        return null;
    };
    var TimeRangeSelectorOption = {
        OPTION_CUSTOM_RELATIVE_RANGE: "CUSTOM_RELATIVE_RANGE",
        OPTION_CUSTOM_DATE_RANGE: "CUSTOM_DATE_RANGE",
        OPTION_LAST_QUARTER: "LAST_QUARTER",
        OPTION_LAST_7_DAYS: "LAST_7_DAYS",
        OPTION_LAST_30_DAYS: "LAST_30_DAYS",
        OPTION_CUSTOM_QUARTER_YEAR: "CUSTOM_QUARTER_YEAR",
        OPTION_ALL_TIME: "ALL_TIME",
    };
    var quarterBasedRanges = [
        TimeRangeSelectorOption.OPTION_CUSTOM_QUARTER_YEAR,
        TimeRangeSelectorOption.OPTION_LAST_QUARTER,
    ];
    var radioItems = [
        {
            name: "Custom Relative Range",
            value: TimeRangeSelectorOption.OPTION_CUSTOM_RELATIVE_RANGE,
        },
        {
            name: "Custom Date Range",
            value: TimeRangeSelectorOption.OPTION_CUSTOM_DATE_RANGE,
        },
        {
            name: "Custom Quarter",
            value: TimeRangeSelectorOption.OPTION_CUSTOM_QUARTER_YEAR,
        },
        {
            name: "Last 30 Days",
            value: TimeRangeSelectorOption.OPTION_LAST_30_DAYS,
        },
        {
            name: "Last 7 Days",
            value: TimeRangeSelectorOption.OPTION_LAST_7_DAYS,
        },
        {
            name: "Last Quarter",
            value: TimeRangeSelectorOption.OPTION_LAST_QUARTER,
        },
        {
            name: "All Time",
            value: TimeRangeSelectorOption.OPTION_ALL_TIME,
        },
    ].reverse();
    var _g = useState(function () {
        var param = searchParams.get("radioSelection");
        return param ? param : TimeRangeSelectorOption.OPTION_ALL_TIME;
    }), radioSelection = _g[0], setRadioSelection = _g[1];
    var _h = useState(function () {
        var val = searchParams.get("minimumKWhFilter");
        var param = val ? parseFloat(val) : undefined;
        return !param || isNaN(param) ? 2 : param;
    }), minimumKWhFilter = _h[0], setMinimumKWhFilter = _h[1];
    var _j = useState(function () {
        var param = searchParams.get("selectedQuarter");
        return param &&
            radioSelection === TimeRangeSelectorOption.OPTION_CUSTOM_QUARTER_YEAR
            ? Number(param)
            : Math.ceil(new Date().getMonth() / 4);
    }), selectedQuarter = _j[0], setSelectedQuarter = _j[1];
    var _k = useState(function () {
        var param = searchParams.get("selectedYear");
        return param &&
            radioSelection === TimeRangeSelectorOption.OPTION_CUSTOM_QUARTER_YEAR
            ? Number(param)
            : new Date().getFullYear();
    }), selectedYear = _k[0], setSelectedYear = _k[1];
    var _l = useMemo(function () {
        var currentQuarter = radioSelection === TimeRangeSelectorOption.OPTION_LAST_QUARTER
            ? Math.ceil(new Date().getMonth() / 4)
            : selectedQuarter;
        var currentYear = radioSelection === TimeRangeSelectorOption.OPTION_LAST_QUARTER
            ? new Date().getFullYear()
            : selectedYear;
        var _a = getPreviousQuarter(currentQuarter, currentYear), comparisonQuarter = _a.previousQuarter, comparisonYear = _a.previousYear;
        return { currentQuarter: currentQuarter, currentYear: currentYear, comparisonQuarter: comparisonQuarter, comparisonYear: comparisonYear };
    }, [radioSelection, selectedQuarter, selectedYear]), currentQuarter = _l.currentQuarter, currentYear = _l.currentYear, comparisonQuarter = _l.comparisonQuarter, comparisonYear = _l.comparisonYear;
    var quarterOptions = [
        { name: "Q1 (Jan-Mar)", value: 1 },
        { name: "Q2 (Apr-Jun)", value: 2 },
        { name: "Q3 (Jul-Sep)", value: 3 },
        { name: "Q4 (Oct-Dec)", value: 4 },
    ];
    var yearOptions = Array.from({ length: new Date().getFullYear() - 2022 }, function (_, i) { return ({
        name: "".concat(new Date().getFullYear() - i),
        value: new Date().getFullYear() - i,
    }); });
    var _m = useState(function () {
        var param = searchParams.get("siteIds");
        return param ? param.split(",") : [];
    }), sitesMultiSelectState = _m[0], setSitesMultiSelectState = _m[1];
    var _o = useState(function () {
        var param = searchParams.get("chargerModels");
        return param ? param.split(",") : [];
    }), chargerModelsMultiSelectState = _o[0], setChargerModelsMultiSelectState = _o[1];
    var relativeDayTextInput = useRef(null);
    var absoluteBeginInput = useRef(null);
    var absoluteEndInput = useRef(null);
    var movingAverageWindowInput = useRef(null);
    var updateSearchParams = function () {
        var _a, _b;
        searchParams.set("radioSelection", radioSelection);
        searchParams.set("selectedQuarter", selectedQuarter.toString());
        searchParams.set("selectedYear", selectedYear.toString());
        searchParams.set("siteIds", sitesMultiSelectState.join(","));
        searchParams.set("chargerModels", chargerModelsMultiSelectState.join(","));
        if (comparisonQuarter !== null) {
            searchParams.set("comparisonQuarter", comparisonQuarter.toString());
        }
        else {
            searchParams.delete("comparisonQuarter");
        }
        if (comparisonYear !== null) {
            searchParams.set("comparisonYear", comparisonYear.toString());
        }
        else {
            searchParams.delete("comparisonYear");
        }
        if (quarterBasedRanges.includes(radioSelection) && minimumKWhFilter) {
            searchParams.set("minimumKWhFilter", minimumKWhFilter.toString());
        }
        var fromDate = formatDateString(((_a = absoluteBeginInput.current) === null || _a === void 0 ? void 0 : _a.value) || null);
        var toDate = formatDateString(((_b = absoluteEndInput.current) === null || _b === void 0 ? void 0 : _b.value) || null);
        if (fromDate) {
            searchParams.set("from", fromDate);
        }
        else {
            searchParams.delete("from");
        }
        if (toDate) {
            searchParams.set("to", toDate);
        }
        else {
            searchParams.delete("to");
        }
        if (relativeDayTextInput.current) {
            searchParams.set("relativeDays", relativeDayTextInput.current.value.toString());
        }
        setSearchParams(searchParams);
    };
    useEffect(function () {
        updateSearchParams();
    }, [
        radioSelection,
        selectedQuarter,
        selectedYear,
        sitesMultiSelectState,
        chargerModelsMultiSelectState,
        comparisonQuarter,
        comparisonYear,
        minimumKWhFilter,
    ]);
    var apiSearchParams = new URLSearchParams();
    apiSearchParams.append("organizationId", "".concat(organizationId));
    var daysToMilliseconds = 1000 * 60 * 60 * 24;
    if (radioSelection == TimeRangeSelectorOption.OPTION_LAST_30_DAYS) {
        apiSearchParams.append("fromNowRelativeMilliseconds", "".concat(30 * daysToMilliseconds));
    }
    else if (radioSelection == TimeRangeSelectorOption.OPTION_LAST_7_DAYS) {
        apiSearchParams.append("fromNowRelativeMilliseconds", "".concat(7 * daysToMilliseconds));
    }
    else if (radioSelection == TimeRangeSelectorOption.OPTION_CUSTOM_RELATIVE_RANGE &&
        relativeDayTextInput.current) {
        var val = Number(relativeDayTextInput.current.value);
        if (val > 0) {
            apiSearchParams.append("fromNowRelativeMilliseconds", "".concat(val * daysToMilliseconds));
        }
    }
    else if (radioSelection == TimeRangeSelectorOption.OPTION_CUSTOM_DATE_RANGE &&
        absoluteBeginInput.current &&
        absoluteEndInput.current) {
        if (absoluteBeginInput.current.value) {
            apiSearchParams.append("from", new Date(absoluteBeginInput.current.value).toISOString());
        }
        if (absoluteEndInput.current.value) {
            apiSearchParams.append("to", new Date(absoluteEndInput.current.value).toISOString());
        }
    }
    else if (quarterBasedRanges.includes(radioSelection)) {
        var startMonth = (currentQuarter - 1) * 3;
        var endMonth = startMonth + 2;
        var from = new Date(currentYear, startMonth, 1);
        var to = new Date(currentYear, endMonth + 1, 0);
        apiSearchParams.append("from", from.toISOString());
        apiSearchParams.append("to", to.toISOString());
        if (minimumKWhFilter) {
            apiSearchParams.append("minimumKWhFilter", minimumKWhFilter.toString());
        }
    }
    // Get the site list, for filtering by site.
    var siteList = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/site/list/").concat(organizationId),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    // Build the site list dropdown menu and state.
    var siteListDropdownMenu = siteList
        ? __spreadArray([
            { name: "All", value: "" }
        ], ((_b = siteList === null || siteList === void 0 ? void 0 : siteList.sites) !== null && _b !== void 0 ? _b : []).map(function (s) { return ({
            name: "".concat(s.id, ": ").concat(s.name),
            value: s.id,
        }); }), true) : [];
    if ((sitesMultiSelectState === null || sitesMultiSelectState === void 0 ? void 0 : sitesMultiSelectState.length) > 0) {
        sitesMultiSelectState.forEach(function (val) {
            return apiSearchParams.append("siteIds", val);
        });
    }
    // Get the charger models, for filtering.
    var chargerModels = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/charger/charger_models"),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    // Build the charger model dropdown menu and state.
    var chargerModelDropdownMenu = convertStringArrayToMultiSelectDropdownItems(chargerModels, true);
    if ((chargerModelsMultiSelectState === null || chargerModelsMultiSelectState === void 0 ? void 0 : chargerModelsMultiSelectState.length) > 0) {
        chargerModelsMultiSelectState.forEach(function (val) {
            return apiSearchParams.append("chargerModels", val);
        });
    }
    // Get the transaction aggregate and timeseries statistics.
    var transactionStats = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/organization/stats?").concat(apiSearchParams.toString()),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var loyaltyTransactionStats = useSWR(isLoading || !isAuthenticated
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/organization/loyalty/stats?").concat(apiSearchParams.toString()),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var retrieveComparison = comparisonQuarter !== null &&
        comparisonYear !== null &&
        quarterBasedRanges.includes(radioSelection) &&
        isAuthenticated;
    if (retrieveComparison) {
        var startMonth = (comparisonQuarter - 1) * 3;
        var endMonth = startMonth + 3;
        apiSearchParams.delete("from");
        apiSearchParams.delete("to");
        apiSearchParams.append("from", new Date(comparisonYear, startMonth, 1).toISOString());
        apiSearchParams.append("to", new Date(comparisonYear, endMonth, 0).toISOString());
    }
    // Get the transaction aggregate and timeseries statistics.
    var fetchedComparisonTransactionStats = useSWR(isLoading || !retrieveComparison
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/organization/stats?").concat(apiSearchParams.toString()),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var comparisonTransactionStats = retrieveComparison
        ? fetchedComparisonTransactionStats
        : undefined;
    var fetchedComparisonLoyaltyTransactionStats = useSWR(isLoading || !retrieveComparison
        ? null
        : [
            "".concat(process.env.CORE_SVC_URL, "/organization/loyalty/stats?").concat(apiSearchParams.toString()),
            getAccessTokenSilently,
        ], Auth0Fetcher).data;
    var comparisonLoyaltyTransactionStats = retrieveComparison
        ? fetchedComparisonLoyaltyTransactionStats
        : undefined;
    return (React.createElement("div", { className: "flex flex-col gap-y-[32px] mt-4" },
        React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
            React.createElement("label", { className: "font-N7 text-black-600" }, "Time Range"),
            React.createElement(Radio, { items: radioItems, value: radioSelection, setValue: function (value) { return setRadioSelection(value); } })),
        radioSelection == TimeRangeSelectorOption.OPTION_CUSTOM_DATE_RANGE && (React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
            React.createElement("label", { className: "font-N7 text-black-600" }, "Start Time"),
            React.createElement(TextInput, { type: "datetime-local", ref: absoluteBeginInput, defaultValue: searchParams.get("absoluteBegin") || "" }),
            React.createElement("label", { className: "font-N7 text-black-600" }, "End Time"),
            React.createElement(TextInput, { type: "datetime-local", ref: absoluteEndInput, defaultValue: searchParams.get("absoluteEnd") || "" }))),
        radioSelection == TimeRangeSelectorOption.OPTION_CUSTOM_QUARTER_YEAR && (React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
            React.createElement("label", { className: "font-N7 text-black-600" }, "Select Quarter"),
            React.createElement(Dropdown, { items: quarterOptions, currentSelection: selectedQuarter, onClick: function (value) { return setSelectedQuarter(value); } }),
            React.createElement("label", { className: "font-N7 text-black-600" }, "Select Year"),
            React.createElement(Dropdown, { items: yearOptions, currentSelection: selectedYear, onClick: function (value) { return setSelectedYear(value); } }))),
        isNoc && quarterBasedRanges.includes(radioSelection) && (React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
            React.createElement("label", { className: "font-N7 text-black-600" }, "Minimum kWh"),
            React.createElement(TextInput, { placeholder: "".concat(minimumKWhFilter !== null && minimumKWhFilter !== void 0 ? minimumKWhFilter : "2"), type: "number", min: 0, className: "w-[80px]", onChange: function (e) {
                    var parsed = parseFloat(e.target.value);
                    setMinimumKWhFilter(isNaN(parsed) ? 0 : parsed);
                } }))),
        radioSelection ==
            TimeRangeSelectorOption.OPTION_CUSTOM_RELATIVE_RANGE && (React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
            React.createElement("label", { className: "font-N7 text-black-600" }, "Days Before Now"),
            React.createElement(TextInput, { type: "number", ref: relativeDayTextInput, defaultValue: searchParams.get("relativeDays") || "1" }))),
        React.createElement("div", { className: "flex flex-row gap-x-4 items-center" },
            isNoc && (React.createElement(React.Fragment, null,
                React.createElement("label", { className: "font-N7 text-black-600" }, "Filter by Charger Model"),
                React.createElement(MultiSelectDropdown, { items: chargerModelDropdownMenu, currentSelections: chargerModelsMultiSelectState, onChange: setChargerModelsMultiSelectState }))),
            React.createElement("label", { className: "font-N7 text-black-600" }, "Filter by Site"),
            React.createElement(MultiSelectDropdown, { items: siteListDropdownMenu, currentSelections: sitesMultiSelectState, onChange: setSitesMultiSelectState }),
            React.createElement("label", { className: "font-N7 text-black-600" }, "Moving Average Window (Days)"),
            React.createElement(TextInput, { type: "number", ref: movingAverageWindowInput, defaultValue: 1 })),
        React.createElement("hr", { className: "border-black-800 max-w-[50%]" }),
        transactionStats && loyaltyTransactionStats && (React.createElement(React.Fragment, null,
            React.createElement(StatsBreakdown, { stats: transactionStats, loyaltyStats: loyaltyTransactionStats, comparisonStats: comparisonTransactionStats, comparisonLoyaltyStats: comparisonLoyaltyTransactionStats, movingAverageWindowSizeDays: Math.round(Number((_d = (_c = movingAverageWindowInput.current) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : "30")), comparisonQuarter: comparisonQuarter, comparisonYear: comparisonYear, currentQuarter: currentQuarter, currentYear: currentYear }),
            React.createElement("hr", { className: "border-black-800 max-w-[50%]" })))));
};
