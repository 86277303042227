import React from "react";
import { NOCControls, } from "./NocControls";
export var NOCStationControls = function (_a) {
    var _b;
    var details = _a.details;
    var powernodeParentConfig = {
        possibleParents: [
            {
                title: details.name,
                id: details.id,
            },
        ],
        title: "Parent Site",
        key: "siteId",
    };
    var chargerParentConfig = {
        possibleParents: details.powernodes
            .sort(function (a, b) { return a.id - b.id; })
            .map(function (pn) {
            return {
                title: "".concat(pn.id),
                id: pn.id,
            };
        }),
        title: "Parent Powernode",
        key: "powernodeId",
    };
    var creatableAssets = [
        {
            title: "Provision New Powernode",
            createUrl: "/powernodes/create",
            parent: powernodeParentConfig,
            subObjects: [
                {
                    metadataUrl: "/powernodes/metadata",
                },
            ],
        },
    ];
    if (details && details.powernodes && details.powernodes.length > 0) {
        creatableAssets.push({
            title: "Provision New Charger",
            createUrl: "/chargers/create",
            parent: chargerParentConfig,
            subObjects: [
                {
                    metadataUrl: "/chargers/metadata",
                },
            ],
        });
    }
    var controllableAssets = [
        {
            title: "".concat(details.name, " (site id ").concat(details.id, ") (Organization '").concat((_b = details === null || details === void 0 ? void 0 : details.organization) === null || _b === void 0 ? void 0 : _b.name, "')"),
            metadataUrl: "/sites/metadata/".concat(details.id),
            setMetadataUrl: "/sites/metadata/set/".concat(details.id),
            deleteDescription: "To delete a site, it must have no powernodes or chargers, and it must not be set to active or to publish. Deleting the site will delete the address and pricing as well.",
            deleteUrl: "/sites/delete/".concat(details.id),
        },
        {
            title: "Address ".concat(details.name),
            metadataUrl: "/sites/address/metadata/".concat(details.id),
            setMetadataUrl: "/sites/address/metadata/set/".concat(details.id),
        },
    ];
    if (details) {
        controllableAssets = controllableAssets.concat(details.powernodes
            .sort(function (a, b) { return a.id - b.id; })
            .map(function (pn) {
            return {
                title: "PNID ".concat(pn.id),
                metadataUrl: "/powernodes/metadata/".concat(pn.id),
                setMetadataUrl: "/powernodes/metadata/set/".concat(pn.id),
                deleteDescription: "To delete a powernode, it must have no chargers, have no recent check-ins, and you must set 'operationalStatus' to MAINTENANCE.",
                deleteUrl: "/powernodes/delete/".concat(pn.id),
            };
        }));
        controllableAssets = controllableAssets.concat(details.chargers
            .sort(function (a, b) { return a.friendlyId - b.friendlyId; })
            .map(function (c) {
            return {
                title: "Charger ".concat(c.ocppId, " (friendly ID ").concat(c.friendlyId, ")"),
                metadataUrl: "/chargers/metadata/".concat(c.id),
                setMetadataUrl: "/chargers/metadata/set/".concat(c.id),
                deleteDescription: "To delete a charger, you must set 'latestChargerStatus' to REMOVED and 'active' to false. It must not be actively checking in.",
                deleteUrl: "/chargers/delete/".concat(c.id),
            };
        }));
    }
    return (React.createElement(NOCControls, { controllableAssets: controllableAssets, creatableAssets: creatableAssets }));
};
